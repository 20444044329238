import useBasicApi from '@/api/useBasicApi'

export default function nearestNotExpiredApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/nearest_not_expired`

  return {
    ENDPOINT,
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
  }
}
